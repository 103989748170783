import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'

import '../styles/about.sass'

const TeamPage = () => {
    return (<Layout>
        <SEO title="L'équipe"/>
        <Banner class_suffix="deep"/>
        <div className="about-container">
            <div className="about-content">
                <h1>Équipe exécutive</h1>
                <center className="committee-tables">
                    <h4>2024 - 2025</h4>
                    <table style={{fontFamily: 'Source Code Pro'}}>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Rôle</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Dora B.</td>
                                <td>Présidente</td>
                            </tr>
                            <tr>
                                <td>Bilal M.</td>
                                <td>VP Training + VP Compétitions</td>
                            </tr>
                            <tr>
                                <td>François M.</td>
                                <td>VP Commandites</td>
                            </tr>
                            <tr>
                                <td>Yoann S. M.</td>
                                <td>VP Comms</td>
                            </tr>
                            <tr>
                                <td>Corentin F.</td>
                                <td>VP Infra</td>
                            </tr>
                        </tbody>
                    </table>
                </center>
            </div>
            <div className="about-content">
                <center className="committee-tables">
                    <h4>2023 - 2024</h4>
                    <table style={{fontFamily: 'Source Code Pro'}}>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Rôle</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Étienne P.</td>
                                <td>Président</td>
                            </tr>
                            <tr>
                                <td>Kaïs M.</td>
                                <td>VP Training + VP Compétitions</td>
                            </tr>
                            <tr>
                                <td>François M.</td>
                                <td>VP Commandites</td>
                            </tr>
                            <tr>
                                <td>Yoann S. M.</td>
                                <td>VP Comms</td>
                            </tr>
                            <tr>
                                <td>Mounir L.</td>
                                <td>VP Infra</td>
                            </tr>
                        </tbody>
                    </table>
                </center>
            </div>
            <div className="about-content">
                <div className="about-content">

                    <h1>Rejoins-nous!</h1>
                    <p>
                        En rejoignant l'équipe exécutive ou l'équipe CTF, tu pourras t'impliquer plus activement dans les activités de notre club, que ce soit pour organiser des événements ou participer aux compétitions de cybersécurité.
                    </p>
                    <div className="team-options">
                        <div className="team-option">
                            <h2>Équipe Exécutive</h2>
                            <p>
                                L'équipe exécutive planifie les ateliers techniques, organise des compétitions CTF (Capture-the-Flag), développe les partenariats avec l'industrie, communique les nouveaux événements et bien plus encore!
                            </p>
                            <a href="https://forms.gle/Q5PedtHzsSjAjCLn6">
                                <button className="signup-button">Rejoins notre équipe Exécutive!</button>
                            </a>
                        </div>
                        <div className="team-option">
                            <h2>Équipe CTF</h2>
                            <p>
                                L'équipe CTF participe à des compétitions tout au long de l'année, incluant des événements prestigieux comme <a href="https://nsec.io/">NorthSec</a> et <a href="https://hfctf.ca/">HackFest</a>. Rejoignez-nous pour repousser vos limites dans des défis techniques de cybersécurité! (*Vous devez être étudiant à Polytechnique Montréal.)
                            </p>
                            <a href="https://forms.gle/nRu5TeagsmhXCqFw8">
                                <button className="signup-button">Rejoins notre équipe CTF!</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    )
}
export default TeamPage
